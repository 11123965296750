<template>
  <div class="category-special">
    <Breadcrumb
      :items="breadcrumb"
      :key="category.categoryId"
      :filtered="true"
    />
    <v-container :class="category.slug">
      <category-title
        class="mt-2 mt-sm-0"
        v-if="category"
        :category="category"
        :showSub="false"
      />
    </v-container>

    <v-container
      v-for="(child, idx) in category.children"
      :key="idx"
      :class="child.slug"
    >
      <ebsn-meta
        tag="h2"
        path="category_info.TITLE"
        :target="child"
        :default="child.name"
      />
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="6"
          v-if="getThumb(child)"
          class="px-5 category-special-thumb-column d-flex"
        >
          <v-img :src="getThumb(child)" contain eager class="ma-auto" />
        </v-col>
        <v-col cols="12" :sm="getThumb(child) ? 6 : 12">
          <ebsn-meta
            tag="div"
            path="category_info.DESCRIPTION"
            :target="child"
          />
        </v-col>
      </v-row>
      <ProductListSlider
        class="mt-3"
        :fetchByCategory="true"
        :categoryId="child.categoryId"
        :paginationClass="
          `swiper-pagination-category-special-${child.categoryId}`
        "
        :title="''"
        :showArrows="$vuetify.breakpoint.smAndUp"
      />
      <ebsn-meta tag="div" path="category_info.FOOTER_TEXT" :target="child" />
    </v-container>
    <v-container v-if="footerText">
      <div v-html="footerText" class="footer-text" />
    </v-container>
  </div>
</template>
<style lang="scss">
.category-special {
  h1 {
    color: var(--v-primary-lighten1) !important;
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 12px;
  }
  h2 {
    color: var(--v-primary-lighten1) !important;
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 8px;
  }
  :deep(.footer-text) {
    background-color: transparent !important;
  }

  .thumb-column:nth-child(even) {
    order: 2;
  }
}
</style>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb";
import ProductListSlider from "@/components/product/ProductListSlider";
import categoryMixins from "~/mixins/category";
import CategoryTitle from "./CategoryTitle.vue";

export default {
  name: "CategorySpecial",
  mixins: [categoryMixins],
  components: {
    Breadcrumb,
    ProductListSlider,
    CategoryTitle
  },
  //props: { category: { type: Object, required: true } },
  data() {
    return { count: -1 };
  },
  computed: {
    footerText() {
      return this.$ebsn.meta(
        this.category,
        "metaData.category_info.FOOTER_TEXT",
        null
      );
    }
  },
  methods: {
    getTitle(category) {
      return this.$ebsn.meta(category, "metaData.category_info.TITLE", null);
    },
    getDescription(category) {
      return this.$ebsn.meta(
        category,
        "metaData.category_info.DESCRIPTION",
        null
      );
    },
    getThumb(category) {
      return this.$ebsn.meta(category, "metaData.category_info.THUMB", null);
    },
    updateCount(count) {
      this.count = count;
    }
  },
  mounted() {
    if (!this.$vuetify.breakpoint.xs) {
      let thumbColumns = document.getElementsByClassName(
        "category-special-thumb-column"
      );
      for (let i = 0; i < thumbColumns.length; i++) {
        if (i % 2 > 0) {
          thumbColumns[i].style.order = 2;
        }
      }
    }
  },
  metaInfo() {
    let text = this.category.name;
    if (this.breadcrumb.length > 0) {
      text = this.breadcrumb[this.breadcrumb.length - 2].text;
    }
    return {
      title: this.$ebsn.meta(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.category ? this.category.name : ""
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.$ebsn.meta(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.secondAndThirdLevel.description", [
              this.category.name,
              text
            ])
          )
        }
      ]
    };
  }
};
</script>
